import React from 'react';
import Timer from './Timer';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import tyhja from './tyhja.png';
import axios from 'axios';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
 

class Room extends React.Component {

    constructor(props) {
    super(props);
   
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClose1 = this.handleClose1.bind(this);
    this.changeControl = this.changeControl.bind(this);
    this.state={
        open:false,
        open1:false,
        text_info:'',
        mode:''
    }

    }

    handleChange = ( event ) => {

        this.setState({open: true, mode:event.target.value});
    }
     
    changeControl () {
    
    this.setState({open: false});

	//post new control_status to database
    const fd = new FormData();
    var status = this.state.mode;

    //to english, because android app used eng names
    if ( status === 'VALVONTA' ) {
    
        status = 'CONTROL'
    
    } else {

        if ( status === 'OPETUS' ) {

            status = 'LEARN'

        } else {

            status = 'OFF'
        }
    }
    var token = this.props.token;

    fd.append('status', status);
    fd.append('token', token);
    axios.post('https://klaudia.fi/CLAUDIA/vbs/statuschange.php', fd

            ).
     then ( res=> {  

        let data = res.data;

            if(data.status_change ==='OK') {

                this.setState({text_info: "Tila on vaihdettu" });

            } else {

                this.setState({text_info: "Tilan vaihto ei onnistunut", mode: this.props.control_status });
            }
        });
        
        this.setState({open1: true});
    }

    handleClose () {
    
        this.setState({open: false, mode: this.props.control_status});
    
    }

    handleClose1 () {

        this.setState({open1: false});
    }

    render() {
	
      return(
        <><React.Fragment>
        <Dialog
            open = {this.state.open}
            onClose = {this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Oletko varma, että haluat muuttaa valvonnan?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id = "alert-dialog-description">
                    Muista, että valvonnan pitää olla aina päällä
                    silloin kun huone ei ole opetusmoodissa tai
                    asukas on poissa kokonaan
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.changeControl}>Kyllä</Button>
                <Button onClick={this.handleClose} autoFocus>
                    En
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open = {this.state.open1}
            onClose = {this.handleClose1}
            aria-labelledby="alert-dialog-title"  
        >
            <DialogTitle id="alert-dialog-title">
            {this.state.text_info}
            </DialogTitle>
            <DialogActions>  
                <Button onClick={this.handleClose1} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment> <>
       <table class='room'>
       <tbody>
        <tr>
            <td class="huone_ylaosa">
                <table class="huone_ylaosa">
                    <tr>
 
                        <td class="huone_nimi">{this.props.room_name}</td>
         		{this.props.control_status !== ''?
			<td class="huone_tila">
                           
                                <TextField 
                                    id="standard-select-currency"
                                    select
                                    label="tila"
                                    value = {this.state.mode===''? this.props.control_status:this.state.mode}
                                    defaultValue = {this.props.control_status}
                                    onChange = {this.handleChange}
                                    sx={{
                                        "& .MuiInput-root": {
                                          color: "#008dd8",
                                          fontFamily: "Arial",
                                          fontWeight: "normal",
                                          // Bottom border
                                          "&:before": {
                                            borderColor: "#008dd8",
                                            borderWidth: "2px",
                                          },
                                        },
                                        // Label
                                        "& .MuiInputLabel-standard": {
                                          color: "#008dd8",
                                          fontWeight: "normal",
                                        },
                                      }}
                                      size = "small"
                                      variant="standard"
                                >
	                                
                                    <MenuItem value={'VALVONTA'}>VALVONTA</MenuItem>
                                    <MenuItem value={'OPETUS'}>OPETUS</MenuItem>
                                    <MenuItem value={'KIINNI'}>KIINNI</MenuItem>

                                </TextField>
                            
                        </td>
			:
			<td class="huone_tila">
			
			</td>
			}
                    </tr>
		  <tr>

			{this.props.monitor_mode === 'Hälytys' || this.props.monitor_mode === 'Laitevika'?
			<td class="info_alarm">
                        {this.props.monitor_mode}
                        </td>
			:
                        <td class="info">
                        {this.props.monitor_mode}
                        </td>
			}
			{this.props.stat_alarms ==='' && this.props.stat_motions===''&& this.props.control_status===''
				?
                        <td class="events">
                        Huone vapaa
                        </td>
			:
                         <>
                        { this.props.control_status === 'KIINNI' ?
                        <td class="events">

                        </td>
                        : <td class="events">
                          {this.props.stat_alarms !== ''? this.props.stat_alarms : 0} hälytysta <br />
                           {this.props.stat_motions !== ''? this.props.stat_motions : 0} liikesarjaa
                        </td>
                        }
                        </>
			}
                    </tr>
                </table>
                   
              	   <div class="timer">
                        {this.props.last_update !== '' && this.props.control_status !== 'KIINNI' ? 
                        <>
                        <Timer last_update={this.props.last_update} />
                        </>
                        : ''
                         } 
                </div> 
            </td>    
        </tr>
        <tr>
            <td>
		{this.props.image_path !== ''? 
                        <>
                       <img class="valvontakuva" src={this.props.image_path } alt=''></img>
                        </>
                        : 
                        <>
                        <img class="valvontakuva" src={tyhja} alt=''></img>
                        </>
                         } 
	    </td>
        </tr>
        </tbody>
      </table>
      </> </>
      )
    }
}

export default Room;

