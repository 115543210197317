import React from 'react';
import { useState, useEffect} from 'react';
import { Box, ThemeProvider, createTheme } from '@mui/system';
import { palette } from '@mui/system';

const Timer =(props)=>{
console.log("props.last_update");
    console.log(props.last_update);
    const theme = createTheme({
        palette: {
            background:{
                paper:'#fff',
            },
	     primary: {
                light: '#008dd8',
                main: '#008dd8',
                dark: '#002884',
                contrastText: '#fff',
              },	    
            text:{
                primary:'#173A5E',
                secondary: '#46505A',
            },
            action:{
                active: '#001E3C',
            },
            success:{
                dark: '#009688',
            },
        },
    });

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    
    const lastMotionDate = props.last_update;
    const getTime = ()=>{
        const time = Date.now()-Date.parse(lastMotionDate);
        setDays(Math.floor(time/(1000*60*60*24)));
        setHours(Math.floor((time/(1000*60*60))%24));
        setMinutes(Math.floor((time/1000/60))%60);
        setSeconds(Math.floor((time/1000)%60));
    };
    useEffect(() => {
        const interval = setInterval(() => getTime(lastMotionDate), 1000);
        return() => clearInterval(interval);
    }, [getTime,lastMotionDate]);
    return(
        <ThemeProvider theme={theme}>
            

     
          {days === 0 ? '' :
 	<Box
          sx={{
            bgcolor: 'primary.light',        
            color: 'primary.contrastText',
            display:'inline',
            boxShadow:1,
            borderRadius:1,
            p:0.5,
            minWidth:5,
            fontWeight: 'medium',
            mx: 0.5,
            fontSize: 10,
          }}
        >
 	{days} p
 	</Box>
}	
        
  {        hours === 0 ? '' :
          <Box
          sx={{
            bgcolor: 'primary.light',        
            color: 'primary.contrastText',
            display: 'inline',
            boxShadow:1,
            borderRadius:1,
            p:0.5,
            minWidth:5,
            fontWeight: 'medium',
            mx: 0.5,
            fontSize: 10,
          }}
         >
        {hours} t
       </Box>} 
       
 {minutes === 0 ? '' :
 <Box
          sx={{
            bgcolor: 'primary.light',        
            color: 'primary.contrastText',
            display: 'inline',
            boxShadow:1,
            borderRadius:1,
            p:0.5,
            minWidth:5,
            fontWeight: 'medium',
            mx: 0.5,
            fontSize: 10,
          }}
         >
	 {minutes} m
	</Box>} 
        
 {seconds === 0 ? '' :
<Box
          sx={{
            bgcolor: 'primary.light',        
            color: 'primary.contrastText',
            display: 'inline',
            boxShadow:1,
            borderRadius:1,
            p:0.5,
            minWidth:5,
            fontWeight: 'medium',
            mx: 0.5,
            fontSize: 10,
          }}
         >
{seconds} s
 </Box>}
        
         </ThemeProvider>
    );
};

export default Timer;

