
import './App.css';
import Room from './Room';
import * as React from 'react';
import virhe from './virhe.png';
import grey from './grey.png';
import learn from './learn.png'; 
import axios from 'axios';

const rooms = [
  {
    move_ends_type: '', 
    last_update: '', 
    room: '', 
    isAlarm: '',
    image_path:'',
    monitor_mode:'',
    status:'',
    token:'',
    alarms:'',
    motions:''
  },
{
    move_ends_type: '', 
    last_update: '', 
    room: '', 
    isAlarm: '',
    image_path:'',
    monitor_mode:'',
    status:'',
    token:'',
    alarms:'',
    motions:''
  },
{   
    move_ends_type: '', 
    last_update: '', 
    room: '', 
    isAlarm: '',
    image_path:'',
    monitor_mode:'',
    status:'',
    token:'',
    alarms:'',
    motions:''
  },
{
    move_ends_type: '', 
    last_update: '', 
    room: '', 
    isAlarm: '',
    image_path:'',
    monitor_mode:'',
    status:'',
    token:'',
    alarms:'',
    motions:''
  },
{  
    move_ends_type: '', 
    last_update: '', 
    room: '', 
    isAlarm: '',
    image_path:'',
    monitor_mode:'',
    status:'',
    token:'',
    alarms:'',
    motions:''
  },
{   
    move_ends_type: '', 
    last_update: '', 
    room: '', 
    isAlarm: '',
    image_path:'',
    monitor_mode:'',
    status:'',
    token:'',
    alarms:'',
    motions:''
  },
{
    move_ends_type: '', 
    last_update: '', 
    room: '', 
    isAlarm: '',
    image_path:'',
    monitor_mode:'',
    status:'',
    token:'',
    alarms:'',
    motions:''
  },
{   
    move_ends_type: '', 
    last_update: '', 
    room: '', 
    isAlarm: '',
    image_path:'',
    monitor_mode:'',
    status:'',
    token:'',
    alarms:'',
    motions:''
  },
{   
    move_ends_type: '', 
    last_update: '', 
    room: '', 
    isAlarm: '',
    image_path:'',
    monitor_mode:'',
    status:'',
    token:'',
    alarms:'',
    motions:''
  },
{
    move_ends_type: '', 
    last_update: '', 
    room: '', 
    isAlarm: '',
    image_path:'',
    monitor_mode:'',
    status:'',
    token:'',
    alarms:'',
    motions:''
  }
];


class  App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
	    alarms:[]
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.monitorStateCheckout = this.monitorStateCheckout.bind(this);
  
  }

  componentDidMount ( ) {

	  this.monitorStateCheckout();	
	  this.startTimer();

  }

  startTimer ( ) {

    if ( this.timer === 0 ) {

      this.timer = setInterval ( this.monitorStateCheckout, 10000 );
    
    }
  }

// get data from database and taking decision what is a status(Learn, Control, Off) and monitor_mode (exp laitevika)

  monitorStateCheckout () {

//date now

    var d1 = new Date ();
    var fin_control_status = '';

//get data from CLAUDIA_CLIENT  table and fill it to rooms
        axios.get('https://klaudia.fi/CLAUDIA/vbs/client.php')
        .then ( data => {

      console.log ("axios get is ");
      console.log (data);

      for (var i = 0; i < data.data.length; i++ ) {

        //CONTROL_STATUS to finish lang

        if ( data.data[i].CONTROL_STATUS === 'CONTROL' ) {

          fin_control_status = 'VALVONTA';

        }

        if ( data.data[i].CONTROL_STATUS === 'LEARN') {
        
          fin_control_status = 'OPETUS';

        }

        if ( data.data[i].CONTROL_STATUS === 'OFF') {
        
          fin_control_status = 'KIINNI';

        }
        
         //"Off" or "Learn or Control" ( in second case check Laitevika( healthdata is < than 60 sec) )

        if ( data.data[i].CONTROL_STATUS === 'OFF' ) {

          rooms[i].image_path = grey;
          rooms[i].monitor_mode ='Asukas on poissa';
         
        } else {  //"Learn or Control" case 

          //check is laite health?
          var healthData = new Date ( data.data[i].HEALTH );
          var hd = new Date ( healthData );

          if ( d1 - hd > 60000 ) {

            rooms[i].image_path = virhe;
            rooms[i].monitor_mode = 'Laitevika';

	        } else {

            if ( data.data[i].CONTROL_STATUS === 'LEARN') {

              rooms[i].monitor_mode = 'Laite oppimassa asukasrutiineja';  
	           
            }
          }
        }
        rooms[i].status = fin_control_status;
	      rooms[i].room = data.data[i].ROOM;
        rooms[i].token = data.data[i].TOKEN;	
      }

        });
      

          //get data from CLAUDIA_ALARM table

          fetch('https://klaudia.fi/CLAUDIA/vbs/alarm.php')
          .then(res => res.json())
          .then(data => {
      
                      if ( data.length === 0 ) {

	                      console.log("tyhjä data from alarm");

	                    } else {

                        for ( var i = 0; i < data.length; i++ ) {

                          var alarm_time = new Date(data[i].alarm_time);
                          data[i].alarm_time = alarm_time;

                        }    
                      }

                      this.setState ( { alarms: data }, () => {
                        console.log("this.state.alarms in alarm");
                        console.log(this.state.alarms) ; } )
          })

          // get data from CLAUDIA_MOTION table

          fetch ( "https://klaudia.fi/CLAUDIA/vbs/motion.php" )
          .then ( res => res.json() )
          .then( data => {
                        var d3;
                        if ( data.length === 0 ) { //motion array is empty

                          if ( this.state.alarms.length !== 0 ) { //alarms array is not empty

                            for (var b = 0; b < this.state.alarms.length; b++ ) {

                              const indexOfRoom = rooms.map(e => e.token).indexOf(this.state.alarms[b].token);
                              if ( indexOfRoom !== -1 ) {
                                rooms[indexOfRoom].isAlarm = true;
                                rooms[indexOfRoom].monitor_mode= "Hälytys";
                                d3 = new Date(this.state.alarms[b].alarm_time);
                                rooms[indexOfRoom].last_update = d3;
                                rooms[indexOfRoom].image_path = 'https://klaudia.fi/CLAUDIA/backend/alarmImages/' + this.state.alarms[b].alarm_image;
                              }
                            }
     
                          } else { //alarms array empty too
                          
                            for ( var a = 0; a < rooms.length; a++ ) {

                                if( rooms[a].status === 'VALVONTA' ) {

                                  rooms[a].monitor_mode ='No motions or alarms yet';

                                }
          
                            }
                          }

                        } else { //motion array is not empty

                          for ( var i = 0; i < data.length; i++ ) {
                            
                            var dataCorrect = new Date(data[i].last_update);
                            const indexOfRoom2 = rooms.map(e => e.token).indexOf(data[i].token);

                            rooms[indexOfRoom2].last_update = dataCorrect;
                            var d2 = new Date(dataCorrect);

                            const index = this.state.alarms.map(e => e.token).indexOf(data[i].token);
                            
                            if ( index === -1 ) {

                              d3 = new Date ( 0 ); // 1970 year

                            } else {

                              d3 = new Date ( this.state.alarms[index].alarm_time );
                            }

                            // Is motion or alarm last?

                            if ( d3 > d2 ) {  //alarm is last
                              if( rooms[indexOfRoom2].monitor_mode !== 'Laitevika'&&
                                  rooms[indexOfRoom2].monitor_mode !== 'Asukas on poissa' ) {

                                  rooms[indexOfRoom2].isAlarm = true;
                                  rooms[indexOfRoom2].monitor_mode= "Hälytys";
                                  rooms[indexOfRoom2].last_update = d3;
                                  rooms[indexOfRoom2].image_path = 'https://klaudia.fi/CLAUDIA/backend/alarmImages/' + this.state.alarms[index].alarm_image;
                                }

                            } else { //motion is last
                              
                              if (data[i].move_ends_type === 'MOVE_ENDS_TO_DOOR') {
          
                                rooms[indexOfRoom2].move_ends_type = 'door';

                              } else {
        
                                rooms[indexOfRoom2].move_ends_type = 'chair';
                              }

                              rooms[indexOfRoom2].isAlarm = false;
                              if( rooms[indexOfRoom2].monitor_mode !== 'Laitevika'&&
                                rooms[indexOfRoom2].monitor_mode !== 'Asukas on poissa' ) {

                                rooms[indexOfRoom2].image_path = 'https://klaudia.fi/CLAUDIA/backend/alarmImages/' + data[i].motion_image;
                              }

                              //Last motion more than 3 min or no, in LEARN status monitor_mode not changing...

                              if( rooms[indexOfRoom2].status === 'VALVONTA' ) {

                                if ( d1 - d2 < 180000 ) {
        
          	                      rooms[indexOfRoom2].monitor_mode= "Liikuttu äsken";
	
                                } else {

        	                        rooms[indexOfRoom2].monitor_mode= "Ei liikettä";
	                              
                                }
                              }
                            }	
                          }
                        }
          }); 
 
          // get alarms statistic

	        fetch ( "https://klaudia.fi/CLAUDIA/vbs/alarms.php" )
	        .then ( res => res.json() )
	        .then( data => {

		                    for ( var i = 0; i < data.length; i++ ) {

                          const index = rooms.map( e => e.token ).indexOf(data[i].TOKEN );

                          if ( index === -1 ) {

				                    //do nothing

		                      } else {

                            rooms[index].alarms= data[i].ALARMCOUNT;
                          }
                        }
          });

          // get motion's statistic

          fetch( "https://klaudia.fi/CLAUDIA/vbs/motions.php" )
          .then( res => res.json() )
          .then( data => {

                          for ( var i = 0; i < data.length; i++ ) {

                            const index = rooms.map(e => e.token).indexOf(data[i].TOKEN);

                            if ( index === -1 ) {

                              //do nothing

                            } else {

                              rooms[index].motions= data[i].MOTIONCOUNT;
                            }
                          }
          });

    

    for ( var i = 0; i < rooms.length; i++ ) {
      console.log ("Tämä on room " + i);
      console.log (rooms[i]);
 
    }
  }
  render () {

    return (

      <div>
        <div>
          <h2>Claudia Huonevalvonta</h2>
        </div>
      <table>
        <tr>
          <td> <Room token = {rooms[0].token}  stat_alarms={rooms[0].alarms} stat_motions={rooms[0].motions} control_status={rooms[0].status} room_name={rooms[0].room} monitor_mode={rooms[0].monitor_mode} image_path={rooms[0].image_path} last_update={rooms[0].last_update}/> </td>
          <td> <Room token = {rooms[1].token} stat_alarms={rooms[1].alarms} stat_motions={rooms[1].motions} control_status={rooms[1].status} room_name={rooms[1].room} monitor_mode={rooms[1].monitor_mode} image_path={rooms[1].image_path} last_update={rooms[1].last_update}/> </td>
          <td> <Room token = {rooms[2].token} stat_alarms={rooms[2].alarms} stat_motions={rooms[2].motions} control_status={rooms[2].status} room_name={rooms[2].room} monitor_mode={rooms[2].monitor_mode} image_path={rooms[2].image_path} last_update={rooms[2].last_update}/> </td>
	        <td> <Room token = {rooms[3].token} stat_alarms={rooms[3].alarms} stat_motions={rooms[3].motions} control_status={rooms[3].status} room_name={rooms[3].room} monitor_mode={rooms[3].monitor_mode} image_path={rooms[3].image_path} last_update={rooms[3].last_update}/> </td>         
	        <td> <Room token = {rooms[4].token} stat_alarms={rooms[4].alarms} stat_motions={rooms[4].motions} control_status={rooms[4].status} room_name={rooms[4].room} monitor_mode={rooms[4].monitor_mode} image_path={rooms[4].image_path} last_update={rooms[4].last_update}/> </td>
        </tr>   
       
        <tr>
          <td> <Room token = {rooms[5].token} stat_alarms={rooms[5].alarms} stat_motions={rooms[5].motions} control_status={rooms[5].status} room_name={rooms[5].room} monitor_mode={rooms[5].monitor_mode} image_path={rooms[5].image_path} last_update={rooms[5].last_update}/> </td>
          <td> <Room token = {rooms[6].token} stat_alarms={rooms[6].alarms} stat_motions={rooms[6].motions} control_status={rooms[6].status} room_name={rooms[6].room} monitor_mode={rooms[6].monitor_mode} image_path={rooms[6].image_path} last_update={rooms[6].last_update}/> </td>
          <td> <Room token = {rooms[7].token} stat_alarms={rooms[7].alarms} stat_motions={rooms[7].motions} control_status={rooms[7].status} room_name={rooms[7].room} monitor_mode={rooms[7].monitor_mode} image_path={rooms[7].image_path} last_update={rooms[7].last_update}/> </td>
          <td> <Room token = {rooms[8].token} stat_alarms={rooms[8].alarms} stat_motions={rooms[8].motions} control_status={rooms[8].status} room_name={rooms[8].room} monitor_mode={rooms[8].monitor_mode} image_path={rooms[8].image_path} last_update={rooms[8].last_update}/> </td>
	        <td> <Room token = {rooms[9].token} stat_alarms={rooms[9].alarms} stat_motions={rooms[9].motions} control_status={rooms[9].status} room_name={rooms[9].room} monitor_mode={rooms[9].monitor_mode} image_path={rooms[9].image_path} last_update={rooms[9].last_update}/> </td>
        </tr>  
      </table>          
      </div> 

    )
  }

}

export default App

